import COMMON from './common';

export const REST_URL = COMMON.BASE_URL;
export const REST_API = {
  LOGOUT: `${REST_URL}/logout`,
  LOGIN: {
    GET_CSRF: `${REST_URL}/csrf-cookie`,
    LOGIN: `${REST_URL}/login/login`,
    UPDATE_PASSWORD: `${REST_URL}/login/update-password`,
  },
  HOME: {
    GET_MACHINE_STATUS_LIST: `${REST_URL}/home/alert-info-list`,
    CORRESPOND_OVERPAY: `${REST_URL}/home/correspond-overpay`,
    UPDATE_ALERT_STATUS: `${REST_URL}/home/update-alert-status`,
    GET_SALES_INFO: `${REST_URL}/home/sales-info`,
    UPDATE_TARGET_COST_RATE: `${REST_URL}/home/update-target-cost-rate`,
    GET_TOP_MACHINE_STATUS_LIST: `${REST_URL}/home/top-alert-info-list`,
  },
  DATA: {
    GET_SALES_VIEW_DATA: `${REST_URL}/data/sales-view-data`,
    GET_COST_RATE_VIEW_DATA: `${REST_URL}/data/cost-rate-view-data`,
    GET_STATION_LIST: `${REST_URL}/data/station-list-data`,
    GET_STATION_TOTAL_DATA_LIST: `${REST_URL}/data/station-total-list-data`,
    DATASHEET: `${REST_URL}/data/datasheet`,
    STATUS_DATASHEET: `${REST_URL}/data/status-datasheet`,
    TOTAL_DATASHEET: `${REST_URL}/data/total-datasheet`,
  },
  MACHINE_CONF: {
    GET_STATION_LIST: `${REST_URL}/machine-conf/station-list`,
    GET_STATION_SERIAL: `${REST_URL}/machine-conf/get-station-serial/{List}`,
    CONF_RECEIVE_CANCEL: `${REST_URL}/machine-conf/conf-receive-cancel/{stationId}`,
    GET_TEST_DATA: `${REST_URL}/machine-conf/conf-all?conf_id=1`,
    GET_CONF_DATA: `${REST_URL}/machine-conf/get-conf/{confId}`,
    GET_CONF_LIST: `${REST_URL}/machine-conf/get-conf-list`,
    SAVE_CONF: `${REST_URL}/machine-conf/save-conf`,
    SAVE_CONF_GRAND: `${REST_URL}/machine-conf/save-conf-grand`,
    SEND_CONF: `${REST_URL}/machine-conf/send-conf`,
    SEND_CONF_TO_JACK: `${REST_URL}/machine-conf/send-conf-to-jack`,
    SEND_CONF_TO_GRAND: `${REST_URL}/machine-conf/send-conf-to-grand`,
    GET_CONF_RECEIVE: `${REST_URL}/machine-conf/get-conf-receive/{List}`,
    GET_CONF_VERSION: `${REST_URL}/machine-conf/get-conf-version/{List}`,
    PIT_CONF_VALIDATION: `${REST_URL}/machine-conf/pit-conf-validation`,
    AREA_CONF_VALIDATION: `${REST_URL}/machine-conf/area-conf-validation`,
    AREA_CONF_VALIDATION_JACK: `${REST_URL}/machine-conf/area-conf-validation-jack`,
    XY_CONF_VALIDATION: `${REST_URL}/machine-conf/xy-conf-validation`,
    CATCHER_SPEED_CONF_VALIDATION: `${REST_URL}/machine-conf/catcher-speed-conf-validation`,
    LEVER_CONF_VALIDATION: `${REST_URL}/machine-conf/lever-conf-validation`,
    SET_NO_CONF: `${REST_URL}/machine-conf/set-no-operation-conf`,
    SET_NO_CONF_WITH_AREA_CONF: `${REST_URL}/machine-conf/set-no-conf-with-area-conf`,
    NEWPAYOUTSUPPORT_CONF_VALIDATION: `${REST_URL}/machine-conf/newpayoutsupport-conf-validation`,
    NEWPAYOUTSUPPORT_CONF_VALIDATION_JACK: `${REST_URL}/machine-conf/newpayoutsupport-conf-validation-jack`,
    SEQUENCE_CONF_VALIDATION: `${REST_URL}/machine-conf/sequence-conf-validation`,
    OTHER_CONF_VALIDATION: `${REST_URL}/machine-conf/other-conf-validation`,
    COST_SERVICE_CONF_VALIDATION: `${REST_URL}/machine-conf/cost-service-conf-validation`,
    DELETE_SAVED_CONF: `${REST_URL}/machine-conf/saved-conf`,
    NEW_SERVICE_MODE_CONF_VALIDATION: `${REST_URL}/machine-conf/new-service-mode-conf-validation`,
    NEW_SERVICE_MODE_CONF_VALIDATION_JACK: `${REST_URL}/machine-conf/new-service-mode-conf-validation-jack`,
    SHIFT_OPERATION_CONF_VALIDATION: `${REST_URL}/machine-conf/shift-operation-conf-validation`,
    SHIFT_OPERATION_CONF_VALIDATION_JACK: `${REST_URL}/machine-conf/shift-operation-conf-validation-jack`,
    LED_CONF_VALIDATION: `${REST_URL}/machine-conf/led-conf-validation`,
    GET_SETTING_DETAIL: `${REST_URL}/machine-conf/get-conf-detail`,
    GET_SETTING_DETAIL_GRAND: `${REST_URL}/machine-conf/get-conf-detail-grand`,
    GET_SUPPORT_INFO_GRAND: `${REST_URL}/machine-conf/get-support-info`,
  },
  GROUP: {
    GET_GROUP_LIST: `${REST_URL}/group/group-list`,
    UPDATE_PIN_STATUS: `${REST_URL}/group/group-pinned`,
    GET_STATION_ID_LIST_IN_GROUP: `${REST_URL}/group/station-id-list-in-group`,
    GET_GIFT_GROUP: `${REST_URL}/group/get-gift-group`,
    GET_GIFT_GROUP_SETTING: `${REST_URL}/group/get-gift-group-setting`,
    GET_GIFT_MACHINE_LIST: `${REST_URL}/group/get-gift-group-machine-list`,
    GET_GIFT_SELECTED_MACHINE: `${REST_URL}/group/get-gift-group-select-machine`,
    LED_GROUP: `${REST_URL}/group/led-group`,
    LED_GROUP_SETTING: `${REST_URL}/group/led-group-setting`,
    LED_GROUP_MACHINE_LIST: `${REST_URL}/group/led-group-machine-list`,
    LED_GROUP_SELECT_MACHINE: `${REST_URL}/group/led-group-select-machine`,
    REGISTER_LED_GROUP: `${REST_URL}/group/register-led-group`,
    DELETE_GROUP_COMPOSITION: `${REST_URL}/group/delete-group/`,
  },
  COMMON: {
    GET_STORE_LIST: `${REST_URL}/common/store/{searchString}`,
    GET_STORE_LIST_INITIAL: `${REST_URL}/common/store`,
    GET_AUTH: `${REST_URL}/common/auth`,
    GET_USER_AUTH: `${REST_URL}/common/user-authentication`,
    GET_SYSTEM_ERROR_INFO: `${REST_URL}/common/system-error-info`,
    CHANGE_CURRENT: `${REST_URL}/common/change-current`,
    CURRENT_DATE: `${REST_URL}/common/current-datetime`,
    GET_VERSION_NO: `${REST_URL}/common/version`,
  },
  MAINTENANCE: {
    GET_USER_INFO_LIST: `${REST_URL}/maintenance/user-info-list`,
    GET_USER_INFO_DETAIL: `${REST_URL}/maintenance/user-info/{userId}`,
    POST_USER_INFO_DETAIL: `${REST_URL}/maintenance/user-create`,
    PUT_USER_INFO_DETAIL: `${REST_URL}/maintenance/user-update`,
    UNLOCK_ACCOUNT: `${REST_URL}/maintenance/user-unlock`,
    DELETE_USER_INFO_DETAIL: `${REST_URL}/maintenance/user-delete`,
    GET_MACHINE_LIST: `${REST_URL}/maintenance/get-machine-list`,
    DELETE_MACHINE: `${REST_URL}/maintenance/delete-machine`,
    SUBMIT_ENTRY_MACHINE: `${REST_URL}/maintenance/entry-machine`,
    MACHINE_ENTRY_STATUS: `${REST_URL}/maintenance/machine_entry_status`,
    MACHINE_STATUS: `${REST_URL}/maintenance/machine_status`,
    MACHINE_UPDATE_STATUS: `${REST_URL}/maintenance/upd_machine_status`,
    GET_MACHINE_GIFT_NAME_LIST: `${REST_URL}/maintenance/gift-list`,
    GET_MACHINE_DETAIL_BY_BOARD_SERIAL: `${REST_URL}/maintenance/machine-detail`,
    GET_ACCESS_MACHINE_LIST: `${REST_URL}/maintenance/get-access-machine-list`,
    GET_POS_LICENSE_COUNT: `${REST_URL}/maintenance/get-license-count`,
    UPDATE_ACCESS_MACHINE_LIST: `${REST_URL}/maintenance/update-linkage`,
    GET_MACHINE_UPDATE_BY_BOARD_SERIAL: `${REST_URL}/maintenance/update-machine`,
    GET_MACHINE_GET_GROUP: `${REST_URL}/maintenance/get-group`,
    CHECK_MACHINE_EXIST: `${REST_URL}/maintenance/check-machine-exist`,
    CHECK_GROUP_EXIST: `${REST_URL}/maintenance/check-group-exist`,
    CHECK_STATION_EXIST: `${REST_URL}/maintenance/check-station-exist`,
    GIFT: {
      GET_MACHINE_GIFT_NAME_LIST: `${REST_URL}/maintenance/gift/gift-list`,
    },
    DELETE_GIFT: `${REST_URL}/maintenance/gift`,
    GET_TEMPLATE: `${REST_URL}/maintenance/template`,
    GET_UPLOAD_GIFT_CSV: `${REST_URL}/maintenance/gift-csv`,
    GET_MACHINE_GIFT_REGISTERED_DATA: `${REST_URL}/maintenance/gift/{giftId}`,
    GET_STATION_LIST: `${REST_URL}/maintenance/get-station-list`,
    POST_ENTRY_GIFT: `${REST_URL}/maintenance/entry-gift`,
    DOWNLOAD_POS_LICENSE_CSV: `${REST_URL}/maintenance/getPosLicenseCsv`,
    GET_DESIGNATED_AUTHORITY: `${REST_URL}/maintenance/getDesignatedAuthority`,
  },
  MANUAL_NOTICE: {
    GET_INFORMATION_PUBLIC: `${REST_URL}/manual-information/public-information`,
    GET_INFORMATION_PRIVATE: `${REST_URL}/manual-information/private-information`,
    GET_MANUAL: `${REST_URL}/manual-information/manual`,
    DOWNLOAD_MANUAL: `${REST_URL}/manual-information/manual/download`,
    UPDATE_BROWSING_HISTORY_DATE: `${REST_URL}/manual-information/browsing-history`,
  },
  OPERATION_EXAMPLE: {
    GET_OPERATION_EXAMPLE: `${REST_URL}/operation-example/operation-example`,
    LOG_OPERATION_EXAMPLE: `${REST_URL}/operation-example/log`,
    SAVE_CONF_OPERATION_EXAMPLE: `${REST_URL}/operation-example/save-conf`,
    DOWNLOAD_PDF: `${REST_URL}/operation-example/download`,
  },
  TEST: {
    STORE: `${REST_URL}/test/store`,
  },
};
